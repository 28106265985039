/**
 These styles would be defined by the merchant, the only hard coded value
 is the class names, unless they are overwritten by the configuration object
 */
.rebilly-framepay.rebilly-input {
    background-color: white;
    height: 36px;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(161, 178, 193, 0.3) inset;
    border: 1px solid #c4ced8;
    transition: border 150ms ease, box-shadow 150ms ease;
    box-sizing: border-box;
    /*overflow: hidden;*/
    position: relative;
}

.rebilly-framepay.rebilly-framepay-focus {
    border-color: #0044d4;
}

.rebilly-framepay.rebilly-framepay-invalid {
    border-color: #e53935;
}

.rebilly-framepay.rebilly-framepay-google-pay {
    height: 48px;
}

#google-pay .rebilly-framepay.rebilly-framepay-google-pay {
    height: 48px;
    min-height: 48px;
    max-height: 100px;
}

#rebilly-apple-pay-button {
    width: 160px;
    height: 40px;
    display: none;
    border-radius: 4px;
    border: none;
    margin: 8px;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: plain;
    -apple-pay-button-style: black;
}

/**
Only applicable for bban accounts
 */
.rebilly-framepay.rebilly-framepay-buttons {
    background: transparent;
    border: none;
    box-shadow: 0 1px 2px 0 rgba(161, 178, 193, 0.3);
    padding: 0;
}

/* grouped rebilly-framepay elements */
.rebilly-framepay-group {
    display: flex;
}

.rebilly-framepay-group > .rebilly-framepay {
    margin-left: 1rem;
}

.rebilly-framepay-group > .rebilly-framepay:first-child {
    margin-left: 0;
}

/*SECONDARY ELEMENTS*/
.rebilly-framepay.rebilly-framepay-secondary {
    height: auto;
    top: 100%;
    margin-top: 2px;

    background-color: white;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgba(161, 178, 193, 0.3);
    border: 1px solid #c4ced8;
    transition: border 150ms ease, box-shadow 150ms ease;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
}

/*DROPDOWN ELEMENTS*/
.rebilly-framepay.rebilly-framepay-dropdown {
    display: none;
    position: absolute;
    padding: 0;
    height: 180px;
    overflow: hidden;
}

/* card dropdown month width */
.rebilly-framepay.rebilly-framepay-dropdown-month {
    width: 3rem;
}

/* card dropdown year width */
.rebilly-framepay.rebilly-framepay-dropdown-year {
    width: 3rem;
}
